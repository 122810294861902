import React from "react";
import { Typography, Button as MuiButton } from "@mui/material";
import Button from "../Button";
import ArrowBack from "../../img/icons/arrow_back.svg";

interface PageHeaderProps {
  title: string;
  btnTitle?: string;
  actionLoading?: boolean;
  hideBtnOnMobile?: boolean;
  subTitle?: string;
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
  onBack?: () => void;
}

const HeaderTitle = ({ title, subTitle }: { title: string; subTitle?: string }) => (
  <div className="flex flex-col">
    <Typography variant="h4">{title}</Typography>
    {subTitle && <Typography>{subTitle}</Typography>}
  </div>
);

export const PageHeader = ({
  title,
  btnTitle,
  subTitle,
  actionLoading = false,
  hideBtnOnMobile = false,
  onBtnClick,
  onBack,
}: PageHeaderProps) => {
  const btnClassNames = hideBtnOnMobile ? "hidden sm:block" : "block";
  return (
    <div className="w-full flex flex-col sm:flex-row justify-between items-center mb-4">
      {!!onBack ? (
        <div className="flex mb-4 sm:mb-0">
          <MuiButton onClick={onBack}>
            <img className="min-w-8 min-h-8" src={ArrowBack} alt="back"></img>
          </MuiButton>
          <HeaderTitle title={title} subTitle={subTitle} />
        </div>
      ) : (
        <div className="mb-4 sm:mb-0">
          <HeaderTitle title={title} subTitle={subTitle} />
        </div>
      )}

      {!!btnTitle && (
        <div className={btnClassNames}>
          <Button
            text={btnTitle}
            loading={actionLoading}
            onClick={onBtnClick}
          />
        </div>
      )}
    </div>
  );
};
