import React from "react";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { colors } from "../../constants/styles";
import { useNavigate } from "react-router-dom";

interface AuthHeaderProps {
  activeTab: "login" | "registration";
}

const defaultStyle = { color: colors.black, fontWeight: 400 };
const activeStyle = { color: colors.black, fontWeight: 700 };

export const AuthHeader = ({ activeTab }: AuthHeaderProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToPage = (name: string) => () => {
    navigate(`/${name}`);
  }

  return (
    <div className="flex justify-between mb-4">
      <Button
        variant="text"
        type="button"
        text={t("Registration")}
        styles={activeTab === "registration" ? activeStyle : defaultStyle}
        onClick={goToPage("registration")}
      />
      <Button
        variant="text"
        type="button"
        text={t("Login")}
        styles={activeTab === "login" ? activeStyle : defaultStyle}
        onClick={goToPage("login")}
      />
    </div>
  );
};
