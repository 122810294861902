import React, { useState } from "react";
import InputField from "../InputField";
import Button from "../Button";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { logger } from "../../util/logger";
import AuthHeader from '../AuthHeader';
import { useNavigate } from 'react-router-dom';

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const resetError = () => {
    setError("");
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      resetError();
    }
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      resetError();
    }
    setPassword(e.target.value);
  };

  const navigateTo = (path: string) => () => {
    navigate(`/${path}`);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      logger.info("Trying to login");
      await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      logger.info("Successfully logged in");
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      logger.error("Error while trying to login", {
        errorCode,
        errorMessage,
      });
      if (
        errorCode === "auth/invalid-email" ||
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/wrong-password"
      ) {
        setError(t("Wrong Email or Password"));
        return;
      }
      toast.error(errorMessage);
    }
  };

  return (
    <div className="lg:w-8/12 sm:w-11/12">
      <AuthHeader activeTab="login" />
      <form onSubmit={handleSubmit} className="w-full mb-4">
        <div className="w-full mb-4">
          <InputField
            className="w-full"
            id="email"
            value={email}
            type="email"
            label={t("Official E-Mail Address")}
            onChange={handleEmailChange}
            error={!!error}
          />
        </div>
        <div className="w-full mb-4">
          <InputField
            id="password"
            value={password}
            type="password"
            label={t("Password")}
            onChange={handlePasswordChange}
            className="w-full"
            error={!!error}
            helperText={error}
          />
        </div>
        <div className="flex justify-center items-center">
          <Button type="submit" text={t("Enter Club")} className="w-full" />
        </div>
      </form>
      <Button
        onClick={navigateTo('forgot-password')}
        variant="text"
        type="button"
        text={t("Forgot Password?")}
      />
      <ToastContainer />
    </div>
  );
};
