import { useEffect, useState, createContext, ReactElement, useContext } from 'react';
import { auth } from "../firebase";
import { Nullable } from '../types/utility';
import { User, onAuthStateChanged, signOut } from "firebase/auth";
import { logger } from '../util/logger';
import { useUserProfile } from './UserProfileContext';

interface IAuthProvider {
  children: ReactElement;
}

interface IAuthContext {
  user: Nullable<User>;
  isLoading: boolean;
  logout: () => void;
}

const defaultAuthStore = {
  user: null,
  isLoading: true,
  logout: () => {},
};

export const AuthContext = createContext<IAuthContext>(defaultAuthStore);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useState<Nullable<User>>(defaultAuthStore.user);
  const [isLoading, setIsLoading] = useState(defaultAuthStore.isLoading);
  const { getUserProfile } = useUserProfile();

  const setLoadingWithDelay = (loading: boolean, delay = 1500) => {
    setTimeout(() => setIsLoading(loading), delay);
  }

  const checkUser = async (data: Nullable<User>) => {
    try {
      setUser(data);
      await getUserProfile(data);
    } catch (err) {
      logger.error("Error in Auth store checkUser subscriber", err);
    } finally {
      // wait second to show app loading animation
      setLoadingWithDelay(false);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (err) {
      logger.error("Error in Auth store logout", err);
    }
  
  }

  useEffect(() => {
    onAuthStateChanged(auth, checkUser);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ user, isLoading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
