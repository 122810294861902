import {
  Box,
  Collapse,
  IconButton,
  Modal,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  Typography,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TrashIcon from "../../img/icons/trash.svg";
import CancelAmenIcon from "../../img/icons/cancel.svg";
import { getEventDate, getIsCanceled } from "../../helpers/eventHelpers";
import { differenceInYears, getYear, isSameDay } from "date-fns";
import { IEvent, IUserInEvent } from "../../types/events";
import { colors } from "../../constants/styles";
import IconBtn from "../IconBtn";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Nullable } from "../../types/utility";
import { IDateParticipation, IParticipation } from "../../types/participation";
import Button from "../Button";
import { InputSelect } from "../InputSelect/InputSelect";

interface CollapsibleRowProps {
  row: {
    date: string;
    users: number;
    time: string;
  };
  event: IEvent;
  eventParticipation: Nullable<IParticipation>;
  cancelEvent: (date: string) => () => void;
  removeAmendment: (date: string) => () => void;
  toggleUserParticipation: (
    userId: string,
    eventId: string,
    date: string
  ) => () => void;
  changedEventParticipation: (
    dateParticipation: Nullable<IDateParticipation>,
    date: string,
    userId: string
  ) => (event: SelectChangeEvent<string>) => void;
}

const getDateParticipation = (
  eventParticipation: Nullable<IParticipation>,
  date: string
) => {
  if (!eventParticipation || !eventParticipation[date]) {
    return [];
  }

  return eventParticipation[date] as IDateParticipation[];
};

const getUserParticipationOverAll = (
  dateParticipation: Nullable<IParticipation>,
  user: IUserInEvent
) => {
  if (!dateParticipation) {
    return [];
  }
  const dates = [];

  for (const date in dateParticipation) {
    if (typeof dateParticipation[date] === "object") {
      const userInDateEvent = dateParticipation[date].find(
        (p) => p.user_id === user.id
      );
      if (userInDateEvent) {
        dates.push({ ...userInDateEvent, date });
      }
    }
  }

  return dates;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const defaultMinutesItems = [
  { value: 0, label: "-" },
  { value: 30, label: "30" },
  { value: 45, label: "45" },
  { value: 60, label: "60" },
  { value: 75, label: "75" },
  { value: 90, label: "90" },
];

export const CollapsibleRow = ({
  row,
  event,
  eventParticipation,
  cancelEvent,
  removeAmendment,
  toggleUserParticipation,
  changedEventParticipation,
}: CollapsibleRowProps) => {
  const [open, setOpen] = React.useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = React.useState(false);
  const [isCancelModalOpened, setIsCancelModalOpened] = React.useState(false);
  const { t } = useTranslation();
  const isCanceled = getIsCanceled(event, row.date);
  const displayDate = `${getEventDate(row.date, true)}.${getYear(row.date)}, ${
    row.time
  }`;

  const applyDeleteModal = () => {
    cancelEvent(row.date)();
    setIsDeleteModalOpened(false);
  };

  const applyCancelModal = () => {
    removeAmendment(row.date)();
    setIsCancelModalOpened(false);
  };

  const dateParticipation = getDateParticipation(eventParticipation, row.date);
  const usersInDay = event.usersInEvent?.filter((user) =>
    user.dates.some((date) => isSameDay(new Date(date), new Date(row.date)))
  );

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            background: isCanceled ? colors.red : colors.white,
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {displayDate}
        </TableCell>
        <TableCell align="right">{usersInDay.length}</TableCell>
        <TableCell align="right">{dateParticipation.length}</TableCell>
        <TableCell align="right">
          {isCanceled ? (
            <IconBtn
              src={CancelAmenIcon}
              alt="cancel"
              onClick={() => setIsCancelModalOpened(true)}
            />
          ) : (
            <IconBtn
              src={TrashIcon}
              alt="cancel"
              onClick={() => setIsDeleteModalOpened(true)}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {`${event.names.de}, ${displayDate}`}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("Name")}</TableCell>
                    <TableCell align="center">{t("Age")}</TableCell>
                    <TableCell align="center">{t("Checkins")}</TableCell>
                    <TableCell align="center">{t("Visited?")}</TableCell>
                    <TableCell align="center">{t("Duration (min)")}</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersInDay ? (
                    usersInDay.map((user) => {
                      const userParticipation = dateParticipation.find(
                        (p) => p.user_id === user.id
                      );
                      const userParticipationOverAll =
                        getUserParticipationOverAll(eventParticipation, user);
                      return (
                        <TableRow key={user.id}>
                          <TableCell align="center">{user.name}</TableCell>
                          <TableCell align="center">
                            {differenceInYears(new Date(), new Date(user.dob))}
                          </TableCell>
                          <TableCell align="center">
                            {userParticipationOverAll.length}
                          </TableCell>
                          <TableCell align="center">
                            <div className="w-full h-full flex items-center justify-center">
                              <div
                                className={`max-h-6 max-w-6 ${
                                  !!userParticipation
                                    ? "bg-green"
                                    : "bg-lightGrey"
                                }`}
                              >
                                <ToggleButton
                                  value="check"
                                  selected={!!userParticipation}
                                  onChange={toggleUserParticipation(
                                    user.id,
                                    event.id,
                                    row.date
                                  )}
                                  sx={{
                                    maxHeight: "24px",
                                    maxWidth: "24px",
                                  }}
                                >
                                  {!!userParticipation ? (
                                    <CheckIcon />
                                  ) : (
                                    <CloseIcon />
                                  )}
                                </ToggleButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <InputSelect
                              items={defaultMinutesItems}
                              selectedValue={
                                userParticipation?.participation_minutes || 0
                              }
                              isRequired={true}
                              id="participation-minutes"
                              onChange={changedEventParticipation(
                                userParticipation,
                                row.date,
                                user.id
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <Typography>{t("No users")}</Typography>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={isDeleteModalOpened}
        onClose={() => setIsDeleteModalOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Are you sure?")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t("This event would be deleted")}
          </Typography>
          <div className="flex justify-end w-full">
            <Button
              text={t("No I don't want")}
              variant="text"
              styles={{ color: colors.primary }}
              onClick={() => setIsDeleteModalOpened(false)}
            />
            <Button
              text={t("JA, BITTE")}
              variant="text"
              styles={{ color: colors.primary }}
              onClick={applyDeleteModal}
            />
          </div>
        </Box>
      </Modal>

      <Modal
        open={isCancelModalOpened}
        onClose={() => setIsCancelModalOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Restore?")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t("Restore canceled event")}
          </Typography>
          <div className="flex justify-end w-full">
            <Button
              text={t("No I don't want")}
              variant="text"
              onClick={() => setIsCancelModalOpened(false)}
              styles={{ color: colors.primary }}
            />
            <Button
              text={t("JA, BITTE")}
              variant="text"
              styles={{ color: colors.primary }}
              onClick={applyCancelModal}
            />
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
