import React from "react";
import { Navigate } from "react-router-dom";

interface PublicRouteProps {
  component: React.ComponentType<any>;
  path: string;
  isAuthenticated: () => boolean;
}

export const PublicRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: PublicRouteProps): React.ReactElement => (
  <>{isAuthenticated() ? <Navigate to="/events" /> : <Component {...rest} />}</>
);
