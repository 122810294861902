import { IconButton, SxProps, Theme } from "@mui/material";

interface IconBtnProps {
  src: string;
  alt: string;
  sx?: SxProps<Theme>;
  onClick: () => void;
}

export const IconBtn = ({ src, alt, sx = {}, onClick }: IconBtnProps) => {
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2, minWidth: 40, minHeight: 40, ...sx }}
      onClick={onClick}
    >
      <img src={src} alt={alt} />
    </IconButton>
  );
};
