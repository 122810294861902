import React from 'react'
import AuthContainer from '../../components/AuthContainer';
import AuthSuccess from '../../components/AuthSuccess';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const SuccessForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <section className="bg-primary h-screen">
      <div className="container mx-auto min-h-full flex items-center justify-center bg-primary">
        <AuthContainer bgColor="bg-mint">
          <AuthSuccess
            title={t("All clear!")}
            text={t("We sent a password to your email")}
            btnText={t("Login")}
            onClick={() => navigate(`/login`)}
          />
        </AuthContainer>
      </div>
    </section>
  );
};
