import React, { useState } from "react";
import InputField from "../InputField";
import Button from "../Button";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import AuthHeader from '../AuthHeader';
import { Link, useNavigate } from 'react-router-dom';
import { validateEmail } from '../../helpers/validation';
import { getFunctions, httpsCallable } from "firebase/functions";
import app, { functionsRegion } from '../../firebase';
import { getRegistrationEmail } from '../../constants';
import { logger } from '../../util/logger';

const functions = getFunctions(app, functionsRegion);

export const RegistrationForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const resetError = () => {
    setError("");
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      resetError();
    }
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      setError(t("Email is required"));
      return;
    }

    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      setError(t("Invalid email format"));
      return;
    }
    try {
      logger.info("Starting registration");
      const addMessage = httpsCallable(functions, "sendEmail");
      addMessage(getRegistrationEmail(email));
      logger.info("Successfully passed registration");
      navigate("/registration-success");
    } catch (error) {
      logger.error("Error while trying to register", error);
      toast.error(t("Error while trying to register"));
    }

  };

  return (
    <div className="lg:w-8/12 sm:w-11/12">
      <AuthHeader activeTab="registration" />
      <form onSubmit={handleSubmit} className="w-full mb-4">
        <div className="w-full mb-4">
          <InputField
            className="w-full"
            id="email"
            value={email}
            type="email"
            label={t("Official E-Mail Address")}
            onChange={handleEmailChange}
            error={!!error}
            helperText={error}
          />
        </div>
        <div className="w-full mb-4">
          <p>
            {t("Claims start")}
            <Link
              to="https://meinklub.app/imprint"
              className="underline text-blue"
            >
              {t("Terms and Conditions")}
            </Link>
            {t("and")}
            <Link
              to="https://meinklub.app/imprint"
              className="underline text-blue"
            >
              {t("Privacy Policy")}
            </Link>
            {t("Claims end")}
          </p>
        </div>
        <div className="flex justify-center items-center">
          <Button
            type="submit"
            text={t("Request password")}
            className="w-full"
          />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
