import React from "react";
import AuthContainer from "../../components/AuthContainer";
import RegistrationForm from '../../components/RegistrationForm';

export const Registration = () => {
  return (
    <section className="bg-primary h-screen">
      <div className="container mx-auto min-h-full flex items-center justify-center bg-primary">
        <AuthContainer>
          <RegistrationForm />
        </AuthContainer>
      </div>
    </section>
  );
};
