import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import FlagIcon from "../../img/icons/flag.svg";
import HomeIcon from "../../img/icons/home.svg";
import SettingsIcon from "../../img/icons/setting.svg";
import classNames from "classnames";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { colors } from "../../constants/styles";
import MobileNavigationModal from "../MobileNavigationModal";
import CustomLink from "../CustomLink";

interface SideBarProps {
  children: React.ReactNode;
}

export const SideBar = ({ children }: SideBarProps) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const routes = [
    {
      path: "/events",
      name: "events",
      label: t("Events"),
      icon: FlagIcon,
    },
    {
      path: "/location",
      name: "location",
      label: t("Location"),
      icon: HomeIcon,
    },
    {
      path: "/settings",
      name: "settings",
      label: t("Settings"),
      icon: SettingsIcon,
    },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpened(!isMobileMenuOpened);
  };

  const currentRoute = routes.find((route) => route.path === location.pathname);

  return (
    <div className="block sm:flex">
      <div className="w-2/12 h-full hidden sm:flex flex-col pl-5 pr-1">
        <Link to="/events">
          <img
            className="max-w-40 max-h-40 mt-4"
            src={require("../../img/logo_navigation.png")}
            alt="Logo"
          />
        </Link>
        <ul className="flex flex-col">
          {routes.map((route) => {
            const linkClassName = classNames(
              "m-2",
              "text-base",
              "font-semibold",
              {
                "text-primary": location.pathname === route.path,
              }
            );
            return (
              <li key={route.name} className='my-1'>
                <span className="flex">
                  <img src={route.icon} alt={route.name} />
                  <Link className={linkClassName} to={route.path}>
                    {route.label}
                  </Link>
                </span>
              </li>
            );
          })}
          <li className="mt-2">
            <span className="flex flex-col max-w-48">
              <p>
                {t(
                  "If you have any problems, technical errors or new ideas, please feel free to contact us by email. We usually respond the same day."
                )}
              </p>
              <CustomLink
                to="mailto:support@meinklub.app"
                title="support@meinklub.app"
                className="mt-1"
                isExternal={true}
              />
            </span>
          </li>
        </ul>
      </div>
      <div className="w-full block sm:hidden bg-primary">
        <AppBar position="fixed" sx={{ background: colors.primary }}>
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              {currentRoute?.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <MobileNavigationModal
          routes={routes}
          isOpen={isMobileMenuOpened}
          pathname={location.pathname}
          toggleModal={toggleMobileMenu}
        />
      </div>
      <section className="w-full sm:w-10/12 mt-14 sm:mt-4">{children}</section>
    </div>
  );
};
