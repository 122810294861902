import { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "../pages/Login";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import Events from "../pages/Events";
import { useAuth } from "../context/AuthContext";
import Registration from "../pages/Registration";
import SuccessRegistration from "../pages/SuccessRegistration";
import Location from "../pages/Location";
import Settings from "../pages/Settings";
import CreateEvent from "../pages/CreateEvent";
import CheckIn from "../pages/CheckIn";
import { useCategories } from "../context/CategoriesContext";
import Lottie from "lottie-react";
import appLoadingAnimation from "../lottie/loading_app.json";
import { useLocations } from "../context/LocationsContext";
import { useUserProfile } from "../context/UserProfileContext";
import { useArea } from "../context/AreaContext";
import { useEvents } from "../context/EventsContext";
import { useParticipation } from "../context/ParticipationContext";
import EditEvent from "../pages/EditEvent";
import ForgotPassword from "../pages/ForgotPassword";
import SuccessForgotPassword from "../pages/SuccessForgotPassword";

const Navigation = () => {
  const { user, isLoading } = useAuth();
  const { userProfile } = useUserProfile();
  const {
    getCategories,
    categories,
    loading: categoryLoading,
  } = useCategories();
  const { getEvents } = useEvents();
  const { getAreas, areas, loading: areasLoading } = useArea();
  const { getLocation } = useLocations();
  const { getParticipation } = useParticipation();

  useEffect(() => {
    if (!user) return;
    if (!categories.length && !categoryLoading) getCategories();
    if (!areas.length && !areasLoading) getAreas();
  }, [categories, areas, categoryLoading, areasLoading, user]);

  const initializeEvents = async () => {
    const events = await getEvents(userProfile!.locations_ids);
    getParticipation(events.map((event) => event.id));
  };

  useEffect(() => {
    if (userProfile?.locations_ids) {
      initializeEvents();
      getLocation(userProfile.locations_ids[0]);
    }
  }, [userProfile?.locations_ids]);

  const isAuthenticated = () => {
    return !!user;
  };

  if (isLoading) {
    return (
      <div>
        <Lottie
          className="w-full h-screen"
          animationData={appLoadingAnimation}
          loop={true}
        />
      </div>
    );
  }
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={PublicRoute({
            path: "/login",
            component: Login,
            isAuthenticated,
          })}
        />
        <Route
          path="/registration"
          element={PublicRoute({
            path: "/registration",
            component: Registration,
            isAuthenticated,
          })}
        />
        <Route
          path="/registration-success"
          element={PublicRoute({
            path: "/registration-success",
            component: SuccessRegistration,
            isAuthenticated,
          })}
        />
        <Route
          path="/forgot-password"
          element={PublicRoute({
            path: "/forgot-password",
            component: ForgotPassword,
            isAuthenticated,
          })}
        />
        <Route
          path="/forgot-password-success"
          element={PublicRoute({
            path: "/forgot-password-success",
            component: SuccessForgotPassword,
            isAuthenticated,
          })}
        />
        <Route
          path="/events"
          element={PrivateRoute({
            path: "/events",
            component: Events,
            isAuthenticated,
            isSidebar: true,
          })}
        />
        <Route
          path="/location"
          element={PrivateRoute({
            path: "/location",
            component: Location,
            isAuthenticated,
            isSidebar: true,
          })}
        />
        <Route
          path="/settings"
          element={PrivateRoute({
            path: "/settings",
            component: Settings,
            isAuthenticated,
            isSidebar: true,
          })}
        />
        <Route
          path="/create-event"
          element={PrivateRoute({
            path: "/create-event",
            component: CreateEvent,
            isAuthenticated,
            isSidebar: true,
          })}
        />

        <Route
          path="/check-in/:eventId"
          element={PrivateRoute({
            path: "/check-in/:eventId",
            component: CheckIn,
            isAuthenticated,
            isSidebar: true,
          })}
        />

        <Route
          path="/edit-event/:eventId"
          element={PrivateRoute({
            path: "/edit-event/:eventId",
            component: EditEvent,
            isAuthenticated,
            isSidebar: true,
          })}
        />
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
