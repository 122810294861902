import { useState, createContext, ReactElement, useContext } from "react";
import { Nullable } from "../types/utility";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import { logger } from "../util/logger";
import { ICategory } from "../types/events";
import { Collections } from "../types/collections";
import { getCollectionData } from '../helpers/firestoreHelpers';

interface ICategoriesProvider {
  children: ReactElement;
}

interface ICategoriesContext {
  categories: ICategory[];
  loading: boolean;
  error: Nullable<string>;
  getCategories: () => void;
}

const defaultUserProfileData = {
  categories: [],
  loading: false,
  error: null,
  getCategories: () => {},
};

export const CategoriesContext = createContext<ICategoriesContext>({
  categories: defaultUserProfileData.categories,
  loading: defaultUserProfileData.loading,
  error: defaultUserProfileData.error,
  getCategories: defaultUserProfileData.getCategories,
});

export const CategoriesProvider = ({ children }: ICategoriesProvider) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState(defaultUserProfileData.loading);
  const [error, setError] = useState(defaultUserProfileData.error);

  const getCategories = async () => {
    try {
      logger.info("Getting Categories");
      setLoading(true);

      const categoriesCollectionRef = collection(db, Collections.Categories);

      const categoriesSnapshot = await getDocs(categoriesCollectionRef);
      const categoriesData = getCollectionData(
        categoriesSnapshot
      ) as unknown as ICategory[];

      if (!categoriesData.length) {
        throw new Error("No Categories found");
      }

      const data = categoriesData.sort((a, b) => a.order - b.order);
      setCategories(data);

      logger.info("Successfully Categories fetched", categoriesData);
    } catch (err: any) {
      logger.error("Error getting Categories", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CategoriesContext.Provider
      value={{ categories, loading, error, getCategories }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => useContext(CategoriesContext);
