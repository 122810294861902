export enum Collections {
  LocationAdmins = "LocationAdmins",
  Events = "Events",
  Categories = "Categories",
  Locations = "Locations",
  Indexing = "EventsIndexing",
  Areas = "Areas",
  UserProfile = 'UserProfile',
  Participation = "Participation",
}
