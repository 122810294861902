import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { IEvent, IEventDoc } from '../types/events';
import { logger } from '../util/logger';
import { transformDateObjToArr } from './eventHelpers';
import { FileContent } from 'use-file-picker/dist/interfaces';
import { deleteObject, getStorage, ref, uploadString } from 'firebase/storage';
import { Id, ToastContent, ToastOptions } from "react-toastify";

const FIREBASE_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;

const getStorageImage = (imagePath: string) => {
  const image = {
    path: imagePath,
    url: "",
  };
  if (!imagePath) return image;

  try {
    image.url = `https://firebasestorage.googleapis.com/v0/b/${FIREBASE_ID}.appspot.com/o/${encodeURIComponent(
      imagePath
    )}?alt=media`;
  } catch (err) {
    logger.error('Error while trying to get storage image',err);
  }
  return image;
};

/**
 *
 * @param collection - Firebase collection
 * @returns documents data
 * Function can be used for unshelling Firebase collection and getting documents data.
 */
export const getCollectionData = (
  collection:QuerySnapshot<DocumentData>,
  replaceFromStart?: number,
) => {
  if (!collection || !collection.docs) {
    return [];
  }
  let documents = collection.docs;

  if (replaceFromStart) {
    documents = documents.slice(0, replaceFromStart);
  }

  return documents.map((doc) => {
    const data = doc.data();
    const id = doc.id;
    return { ...data, id };
  });
};

export const mapEventsToObject = (events: IEventDoc[]) => {
  const eventsData = [];

  for (let index = 0; index < events.length; index++) {
    const event = events[index];
    const days = transformDateObjToArr(event.days);
    const image = getStorageImage(event.image);
    const category = event.category_details;
    const location = event.location_details;

    eventsData.push({
      ...event,
      category,
      location,
      usersInEvent: event.usersInEvent || [],
      days,
      image,
    });
  }

  return eventsData as unknown as IEvent[];
};

export const uploadImage = async (
  path: string,
  existingImagePath: string,
  filesContent: FileContent<string>[],
  toast: {error: (content: ToastContent<unknown>, options?: ToastOptions<unknown>) => Id},
) => {
  if (filesContent.length) {
    const file = filesContent[0];
    const storage = getStorage();
    if (existingImagePath) {
      try {
        // Delete the old image
        const oldImageRef = ref(storage, existingImagePath);
        await deleteObject(oldImageRef);
      } catch (err: any) {
        const errorMessage = err.message;
        logger.error("Error while deleting old image", { err, errorMessage });
      }
    }

    try {
      const imageRef = ref(storage, path);
      await uploadString(imageRef, file.content, "data_url");

      return path;
    } catch (err: any) {
      const errorMessage = err.message;
      logger.error("Error while uploading image", { err, errorMessage });
      toast.error(errorMessage);
    }
  }
  return existingImagePath;
};

export const getStorageImageURL = (imagePath: string) => {
  return `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_FIREBASE_PROJECT_ID
  }.appspot.com/o/${encodeURIComponent(imagePath)}?alt=media`;
}
