import React from 'react'
import AuthContainer from '../../components/AuthContainer';
import LoginForm from '../../components/LoginForm';

export const Login = () => {
  return (
    <section className="bg-primary h-screen">
      <div className="container mx-auto min-h-full flex items-center justify-center bg-primary">
        <AuthContainer>
            <LoginForm />
        </AuthContainer>
      </div>
    </section>
  );
}
