import { colors } from '../../constants/styles';

export const buttonStyles = {
  contained: {
    background: colors.primary,
  },
  text: {
    color: colors.grey,
  },
  outlined: {
    color: colors.primary,
  },
};