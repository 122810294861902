import { useState, createContext, ReactElement, useContext } from "react";
import { Nullable } from "../types/utility";
import { User } from "firebase/auth";
import { logger } from "../util/logger";
import { IUserProfile } from "../types/user";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Collections } from '../types/collections';

interface IUserProfileProvider {
  children: ReactElement;
}

interface IUserProfileContext {
  userProfile: Nullable<IUserProfile>;
  getUserProfile: (data: Nullable<User>) => void;
  updateUserProfile: (data: IUserProfile) => void;
}

const defaultUserProfileStore = {
  userProfile: null,
  getUserProfile: () => {},
  updateUserProfile: () => {},
};

export const UserProfileContext = createContext<IUserProfileContext>(defaultUserProfileStore);

export const UserProfileProvider = ({ children }: IUserProfileProvider) => {
  const [userProfile, setUserProfile] = useState<Nullable<IUserProfile>>(null);

  const getUserProfile = async (data: Nullable<User>) => {
    try {
      if (!data) {
        logger.info("Can't get user, data is empty");
        return;
      }

      if (userProfile) {
        logger.info("UserProfile already set");
        return;
      }

      const docRef = doc(db, Collections.LocationAdmins, data.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserProfile(docSnap.data() as IUserProfile);
        logger.info("User profile set!");
      } else {
        logger.error("No such user profile!");
      }
    } catch (err) {
      logger.error("Error in User store getUserProfile", err);
    }
  };

  const updateUserProfile = async (data: IUserProfile) => {
    try {
      const newData = { ...data, updated_at: new Date().getTime() };
      const collectionRef = collection(db, Collections.LocationAdmins);
      await updateDoc(
        doc(collectionRef, data.id),
        newData as {
          [x: string]: any;
        }
      );
      setUserProfile(newData);
    } catch (err) {
      logger.error("Error in User store updateUserProfile", err);
    }
  }

  return (
    <UserProfileContext.Provider
      value={{ userProfile, getUserProfile, updateUserProfile }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => useContext(UserProfileContext);
