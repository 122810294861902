import { useState, createContext, ReactElement, useContext } from "react";
import { Nullable } from "../types/utility";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { logger } from "../util/logger";
import { Collections } from "../types/collections";
import { getCollectionData } from "../helpers/firestoreHelpers";
import { IDateParticipation, IParticipation } from "../types/participation";

interface IParticipationProvider {
  children: ReactElement;
}

interface IParticipationContext {
  participation: IParticipation[];
  loading: boolean;
  error: Nullable<string>;
  getParticipation: (event_ids: string[]) => void;
  participate: (
    userId: string,
    eventId: string,
    date: string,
    participation_minutes?: number
  ) => void;
  changeParticipationMinutes: (
    userId: string,
    eventId: string,
    date: string,
    participation_minutes: number
  ) => void;
}

const defaultParticipationData = {
  participation: [],
  loading: false,
  error: null,
  getParticipation: () => {},
  participate: () => {},
  changeParticipationMinutes: () => {},
};

export const ParticipationContext = createContext<IParticipationContext>({
  participation: defaultParticipationData.participation,
  loading: defaultParticipationData.loading,
  error: defaultParticipationData.error,
  getParticipation: defaultParticipationData.getParticipation,
  participate: defaultParticipationData.participate,
  changeParticipationMinutes: defaultParticipationData.changeParticipationMinutes,
});

export const ParticipationProvider = ({ children }: IParticipationProvider) => {
  const [participation, setParticipation] = useState<IParticipation[]>([]);
  const [loading, setLoading] = useState(defaultParticipationData.loading);
  const [error, setError] = useState(defaultParticipationData.error);

  const getParticipation = async (event_ids: string[]) => {
    try {
      logger.info("Getting Participation");
      if (!event_ids.length) {
        logger.info("There is no event ids to get participation");
        return;
      }
      setLoading(true);
      const participationCollectionRef = collection(
        db,
        Collections.Participation
      );
      const q = query(participationCollectionRef, where("id", "in", event_ids));

      const participationSnapshot = await getDocs(q);
      const participationData = getCollectionData(
        participationSnapshot
      ) as unknown as IParticipation[];

      setParticipation(participationData);

      logger.info("Successfully Participation fetched", participationData);
    } catch (err: any) {
      logger.error("Error getting Participation", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const participate = async (
    userId: string,
    eventId: string,
    date: string,
    participation_minutes?: number
  ) => {
    try {
      setLoading(true);
      const now = new Date().getTime();
      const participationRef = collection(db, Collections.Participation);
      const docRef = doc(participationRef, eventId);

      const eventParticipation =
        participation.find((p) => p.id === eventId) || ({} as IParticipation);
      const dateParticipation =
        (eventParticipation[date] as IDateParticipation[]) || [];
      const isUserClaimed = dateParticipation.find((p) => p.user_id === userId);
      let data = {};

      if (!!isUserClaimed) {
        data = {
          ...eventParticipation,
          id: eventId,
          [date]: dateParticipation.filter((p) => p.user_id !== userId),
        };
      } else {
        data = {
          ...eventParticipation,
          id: eventId,
          [date]: [
            ...dateParticipation,
            {
              user_id: userId,
              claimed_date: now,
              participation_minutes: participation_minutes || 0,
            },
          ],
        };
      }

      await setDoc(docRef, data);
      const newParticipation = participation.filter((p) => p.id !== eventId);
      setParticipation([...newParticipation, data as IParticipation]);

      logger.info("User participation successfully set", {
        isUserClaimed: !isUserClaimed,
      });
    } catch (err: any) {
      logger.error("Error setting user participation", err);
      setError(err.message);
    }
  };

  const changeParticipationMinutes = async (
    userId: string,
    eventId: string,
    date: string,
    participation_minutes: number,
  ) => {
    try {
      setLoading(true);
      const participationRef = collection(db, Collections.Participation);
      const docRef = doc(participationRef, eventId);

      const eventParticipation =
        participation.find((p) => p.id === eventId) || ({} as IParticipation);
      const dateParticipation =
        (eventParticipation[date] as IDateParticipation[]) || [];
      const data = {
        ...eventParticipation,
        id: eventId,
        [date]: dateParticipation.map((p) => {
          if (p.user_id === userId) {
            return { ...p, participation_minutes: participation_minutes };
          }
          return p;
        }),
      };

      await setDoc(docRef, data);
      const newParticipation = participation.filter((p) => p.id !== eventId);
      setParticipation([...newParticipation, data as IParticipation]);
    } catch (err: any) {
      logger.error("Error setting user participation", err);
      setError(err.message);
    }
  };

  return (
    <ParticipationContext.Provider
      value={{
        participation,
        loading,
        error,
        getParticipation,
        participate,
        changeParticipationMinutes,
      }}
    >
      {children}
    </ParticipationContext.Provider>
  );
};

export const useParticipation = () => useContext(ParticipationContext);
