import { Nullable } from "../types/utility";

class Logger {
  log: Nullable<any> = null;
  constructor() {
    this.log = console;
  }

  public info = (message: string, data?: any) => {
    this.log.log(message, data);
  };

  public debug = (message: string, data?: any) => {
    this.log.debug(message, data);
  };

  public warn = (message: string, data?: any) => {
    this.log.warn(message, data);
  };

  public error = (message: string, data?: any) => {
    this.log.error(message, data);
  };
}

export const logger = new Logger();
