import AuthContainer from '../../components/AuthContainer';
import AuthSuccess from '../../components/AuthSuccess';
import { useTranslation } from 'react-i18next';

export const SuccessRegistration = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-primary h-screen">
      <div className="container mx-auto min-h-full flex items-center justify-center bg-primary">
        <AuthContainer bgColor="bg-mint">
          <AuthSuccess
            title={t("Congrats")}
            text={t(
              "You have successfully registered our team will contact you wia email"
            )}
          />
        </AuthContainer>
      </div>
    </section>
  );
}
