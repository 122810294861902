import AuthContainer from '../../components/AuthContainer';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';

export const ForgotPassword = () => {
  return (
    <section className="bg-primary h-screen">
      <div className="container mx-auto min-h-full flex items-center justify-center bg-primary">
        <AuthContainer>
          <ForgotPasswordForm />
        </AuthContainer>
      </div>
    </section>
  );
}
