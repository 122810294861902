import classNames from 'classnames';
import React from 'react'

interface AuthContainerProps {
  children: React.ReactNode;
  bgColor?: string;
}

export const AuthContainer = ({
  children,
  bgColor = "bg-white",
}: AuthContainerProps) => {
  const containerClassName = classNames(
    "w-10/12",
    "h-4/6",
    "md:w-7/12",
    "flex",
    "items-center",
    "justify-center",
    "p-10",
    "rounded-lg",
    bgColor
  );
  return (
    <div className="flex flex-col w-full items-center justify-center">
      <div className="w-10/12 h-4/6 md:w-7/12 mb-8 flex items-center justify-center">
        <img src={require("../../img/logo.png")} alt="logo" />
      </div>
      <div className={containerClassName}>{children}</div>
    </div>
  );
};
