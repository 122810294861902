import React from "react";
import Button from "../../components/Button";
import { useAuth } from "../../context/AuthContext";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "../../context/UserProfileContext";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import { Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { emailRegexp } from "../../constants";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updateEmail, updatePassword } from "firebase/auth";
import { logger } from "../../util/logger";
import CustomLink from '../../components/CustomLink';

type EventFormFields =
  | "newEmail"
  | "repeatEmail"
  | "oldPassword"
  | "newPassword"
  | "repeatPassword";

const defaultFormsState = () => ({
  newEmail: "",
  repeatEmail: "",
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
});

export const Settings = () => {
  const [formState, setFormState] = React.useState(defaultFormsState());

  const { logout } = useAuth();
  const { userProfile, updateUserProfile } = useUserProfile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = getAuth();

  const handleFormChange =
    (field: EventFormFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;

      setFormState({ ...formState, [`${field}`]: value });
    };

  if (!userProfile) {
    navigate("/login");
    return null;
  }
  const saveEmail = async () => {
    if (!auth.currentUser) {
      logger.error("Can't get user, data is empty");
      toast.error(t("Opps something went wrong"));
      return;
    }

    if (formState.newEmail !== formState.repeatEmail) {
      toast.error(t("Email does not match"));
      return;
    }

    if (!emailRegexp.test(formState.newEmail)) {
      toast.error(t("Invalid email format"));
      return;
    }

    try {
      await updateEmail(auth.currentUser, formState.newEmail);
      await updateUserProfile({ ...userProfile, email: formState.newEmail });
      setFormState({ ...formState, newEmail: "", repeatEmail: "" });
      toast.success(t("Email updated"));
    } catch (error) {
      logger.error("Error while trying to save email", error);
      toast.error(t("Opps something went wrong"));
    }
  };

  const savePassword = async () => {
    if (!auth.currentUser) {
      logger.error("Can't get user, data is empty");
      toast.error(t("Opps something went wrong"));
      return;
    }

    if (formState.newPassword !== formState.repeatPassword) {
      toast.error(t("Password does not match"));
      return;
    }

    if (formState.newPassword.length < 4) {
      toast.error(t("New password is too short"));
      return;
    }

    // check that old password is correct
    try {
      const credential = EmailAuthProvider.credential(
        userProfile.email,
        formState.oldPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
    } catch (error) {
      logger.error("Error while trying to reauthenticate", error);
      toast.error(t("Old password is incorrect"));
      return;
    }

    try {
      await updatePassword(auth.currentUser, formState.newPassword);
      setFormState({ ...formState, oldPassword: "", newPassword: "", repeatPassword: "" });
      toast.success(t("Password updated"));
    } catch (error) {
      logger.error("Error while trying to save password", error);
      toast.error(t("Opps something went wrong"));
    }
  };

  const defaultMargin = "mb-5";
  return (
    <PageContainer>
      <PageHeader
        title={`${userProfile.first_name} ${userProfile.last_name}`}
        btnTitle={t("Log out")}
        onBtnClick={logout}
      />
      <div className="flex flex-col sm:flex-row">
        <div className="w-full sm:w-1/2 flex sm:block flex-col justify-center items-center">
          <div className="my-5">
            <Typography>{t("E-Mail")}</Typography>
          </div>
          <div className="w-full sm:w-3/4">
            <InputField
              containerClassNames={defaultMargin}
              className="w-full"
              id="email-field"
              value={userProfile.email}
              type="text"
              label=""
              disabled={true}
              onChange={() => {}}
            />
            <InputField
              containerClassNames={defaultMargin}
              className="w-full"
              id="new-email-field"
              value={formState.newEmail}
              type="email"
              label={t("New")}
              onChange={handleFormChange("newEmail")}
            />
            <InputField
              containerClassNames={defaultMargin}
              className="w-full"
              id="repeat-email-field"
              value={formState.repeatEmail}
              type="email"
              label={t("Repeat")}
              onChange={handleFormChange("repeatEmail")}
            />
            <div className="flex justify-end items-center">
              <Button text={t("Save Email")} onClick={saveEmail} />
            </div>
            <div className='mt-7'>
              <Typography>{t("Do you have any questions?")}</Typography>
              <CustomLink
                to="mailto:support@meinklub.app"
                title="support@meinklub.app"
                className="mt-1"
                isExternal={true}
              />
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 flex sm:block flex-col justify-center items-center">
          <div className="my-5">
            <Typography>{t("Change Password")}</Typography>
          </div>
          <div className="w-full sm:w-3/4">
            <InputField
              containerClassNames={defaultMargin}
              className="w-full"
              id="password-field"
              value={formState.oldPassword}
              type="password"
              label={t("Old")}
              onChange={handleFormChange("oldPassword")}
            />
            <InputField
              containerClassNames={defaultMargin}
              className="w-full"
              id="new-password-field"
              value={formState.newPassword}
              type="password"
              label={t("New")}
              onChange={handleFormChange("newPassword")}
            />
            <InputField
              containerClassNames={defaultMargin}
              className="w-full"
              id="repeat-password-field"
              value={formState.repeatPassword}
              type="password"
              label={t("Repeat")}
              onChange={handleFormChange("repeatPassword")}
            />
            <div className="flex justify-end items-center">
              <Button text={t("Save Password")} onClick={savePassword} />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </PageContainer>
  );
};
