
export const colors = {
  primary: "#8648E6",
  black: "#000000",
  white: "#FFFFFF",
  grey: "#808080",
  blue: "#0000FF",
  mint: "#76DACE",
  label: "#0009",
  red: "#FCDAD4",
  lightGrey: "#F9F7F7",
  green: "#E0EDD3",
};
