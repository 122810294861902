import { Button, Tooltip } from '@mui/material';
import React from 'react'

interface ButtonTooltipProps {
    title: string;
    icon: string;
    alt: string;
}

export const ButtonTooltip = ({ title, icon, alt }: ButtonTooltipProps) => {
  return (
    <Tooltip title={title}>
      <Button className="ml-2">
        <img className="max-w-6 max-h-6" src={icon} alt={alt}></img>
      </Button>
    </Tooltip>
  );
};
