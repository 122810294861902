import {
  useState,
  useContext,
  ChangeEvent,
  useEffect,
  MouseEventHandler,
} from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import { useLocations } from "../../context/LocationsContext";
import PageContainer from "../../components/PageContainer";
import {
  defaultLocationErrorState,
  defaultLocationFormState,
  generateSmallRandomId,
  transformItemsForSelect,
} from "../../helpers/formHelpers";
import { LocationFormFields, SelectItem } from "../../types/forms";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import { InputSelect } from "../../components/InputSelect/InputSelect";
import { AreasContext } from "../../context/AreaContext";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
} from "@mui/material";
import RadioButtons from "../../components/RadioButtons";
import {
  generateAccessibilityOptions,
  generateAgeOptions,
  generateGenderOptions,
  locationTypesList,
  requiredLocationFields,
} from "../../constants/forms";
import TimeRange from "../../components/TimeRange";
import { Gender } from "../../types/events";
import ImagePicker from "../../components/ImagePicker";
import { useFilePicker } from "use-file-picker";
import {
  FileAmountLimitValidator,
  FileTypeValidator,
} from "use-file-picker/validators";
import {
  getStorageImageURL,
  uploadImage,
} from "../../helpers/firestoreHelpers";
import { ToastContainer, toast } from "react-toastify";
import { ImageCustomValidator } from "../../helpers/ImageCustomValidator";

export const Location = () => {
  const [formState, setFormState] = useState(defaultLocationFormState());
  const [formLoading, setFormLoading] = useState(false);
  const [formErrorState, setFormErrorState] = useState(
    defaultLocationErrorState()
  );

  const { location, updateLocation } = useLocations();
  const { areas } = useContext(AreasContext);
  const { t } = useTranslation();
  const { openFilePicker, filesContent } = useFilePicker({
    accept: "image/*",
    multiple: false,
    readAs: "DataURL",
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(["png", "jpg", "webp"]),
      new ImageCustomValidator(),
    ],
  });

  useEffect(() => {
    if (!location) {
      return;
    }

    const address_arr = location.address.split(",");
    let city = "";
    let postal_code = "";
    if (address_arr.length > 1) {
      const cityInfo = address_arr[1].trim().split(" ");
      if (cityInfo.length > 1) {
        city = cityInfo[1] || "";
        postal_code = cityInfo[0] || "";
      }
    }
    const instagram_url =
      location.social.find((social) => social.name === "instagram")?.url || "";
    const tiktok_url =
      location.social.find((social) => social.name === "tiktok")?.url || "";
    const image = {
      url: location.image ? getStorageImageURL(location.image) : "",
      path: location.image || "",
    };
  
    setFormState({
      name: location.names.de || "",
      street: address_arr[0],
      city,
      postal_code: postal_code,
      area: areas.find((area) => area.id === location.area_id)!,
      minAge: `${location.ageRules?.min}` || "",
      maxAge: `${location.ageRules?.max}` || "",
      accessibility: location.accessibility,
      phone: location.phone,
      email: location.email,
      website: location.website,
      manager: location.manager || "",
      gender: location.gender || Gender.All,
      holder: location.holder || "",
      image,
      contactPerson: location.contactPerson || "",
      mobileNumber: location.mobileNumber || "",
      instagram: instagram_url ? instagram_url.split("/")[3] : "",
      tiktok: tiktok_url ? tiktok_url.split("/")[3] : "",
      location_type: location.location_type,
      openingTime: location.openingTime || "",
      lgbtqi: !!location.lgbtqi,
    });
  }, [location, areas]);

  if (!location) {
    return null;
  }

  const handleFormChange =
    (field: LocationFormFields) => (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      resetError(field);
      const { value } = e.target;

      if (field === "lgbtqi") {
        setFormState({ ...formState, [`${field}`]: !formState.lgbtqi });
        return;
      }

      setFormState({ ...formState, [`${field}`]: value });
    };

  const resetError = (field: string) => {
    if (field === "minAge" || field === "maxAge") {
      setFormErrorState({ ...formErrorState, minAge: "", maxAge: "" });
      return;
    }

    setFormErrorState({ ...formErrorState, [`${field}`]: "" });
  };

  const onSelectChange =
    (selectName: LocationFormFields) =>
    (event: SelectChangeEvent<string | boolean>) => {
      event.preventDefault();
      resetError(selectName);
      const { value } = event.target;

      if (selectName === "area") {
        setFormState({
          ...formState,
          area: areas.find((area) => area.id === value)!,
        });
        return;
      }

      // check if age range is right
      if (selectName === "minAge" || selectName === "maxAge") {
        const minAge = selectName === "minAge" ? value : formState.minAge;
        const maxAge = selectName === "maxAge" ? value : formState.maxAge;

        if (minAge && maxAge && Number(minAge) > Number(maxAge)) {
          const message = t("Min age should be less than max age");
          toast.error(t(message));
          setFormErrorState({
            ...formErrorState,
            minAge: message,
            maxAge: message,
          });
        }
      }

      setFormState({ ...formState, [`${selectName}`]: value });
    };
  const onFileChange: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openFilePicker();
  };

  const validateForm = () => {
    let isValid = true;
    const errorState = {};

    requiredLocationFields.forEach((field) => {
      if (!formState[field as keyof LocationFormFields]) {
        errorState[field] = "Required";
        isValid = false;
      }
    });

    if (!isValid) {
      toast.error(t("Required fields is not filled"));
      setFormErrorState({ ...formErrorState, ...errorState });
    }

    // check if min age is less than max age
    if (formErrorState.minAge || formErrorState.maxAge) {
      isValid = false;
      toast.error(t("Min age should be less than max age"));
    }

    return isValid;
  };

  const onSave = async () => {
    if (!validateForm()) {
      return;
    }

    setFormLoading(true);

    const imagePath = await uploadImage(
      `images/locations/${location.id}-${generateSmallRandomId()}`,
      formState.image.path,
      filesContent,
      toast
    );
    await updateLocation(formState, location.id, imagePath);

    setFormLoading(false);
    toast.success(t("Location saved successfully"));
  };

  const defaultMargin = "mb-5";

  const selectCategoryItems = transformItemsForSelect(areas as SelectItem[]);

  return (
    <PageContainer>
      <PageHeader
        title={location.names.de || t("Location")}
        subTitle={location.id || ""}
        btnTitle={t("Save")}
        actionLoading={formLoading}
        hideBtnOnMobile={true}
        onBtnClick={onSave}
      />
      <form className="w-full flex flex-col sm:flex-row justify-between">
        <div className="w-full sm:w-1/2">
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="name-field"
            value={formState.name}
            error={!!formErrorState.name}
            type="text"
            label={t("Location Name")}
            onChange={handleFormChange("name")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="street-field"
            value={formState.street}
            type="text"
            error={!!formErrorState.street}
            label={t("Street and house number")}
            onChange={handleFormChange("street")}
          />
          <div className="w-3/4 flex flex-row justify-between">
            <InputField
              containerClassNames={`${defaultMargin} sm:w-3/12 w-4/12`}
              id="w-full"
              value={formState.postal_code}
              error={!!formErrorState.postal_code}
              type="text"
              label={t("Postal code")}
              onChange={handleFormChange("postal_code")}
            />
            <InputField
              containerClassNames={`${defaultMargin} sm:w-8/12 w-7/12`}
              className="w-full"
              id="city-field"
              value={formState.city}
              error={!!formErrorState.city}
              type="text"
              label={t("City")}
              onChange={handleFormChange("city")}
            />
          </div>
          <InputSelect
            items={selectCategoryItems}
            selectedValue={formState.area?.id || ""}
            error={!!formErrorState.area}
            label={t("Area")}
            id="area-select"
            containerClassName={defaultMargin}
            className="w-3/4"
            onChange={onSelectChange("area")}
          />
          <RadioButtons
            id="target-group-select"
            name="target-group"
            label={t("General target group")}
            options={generateGenderOptions()}
            defaultValue="all"
            value={formState.gender}
            onChange={onSelectChange("gender")}
          />
          <div className="mt-2">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.lgbtqi}
                    onClick={handleFormChange("lgbtqi")}
                  />
                }
                label={t("LGBTQI")}
                value={formState.lgbtqi}
                labelPlacement="end"
              />
            </FormGroup>
          </div>
          <TimeRange
            id="age-range"
            label={t("General age")}
            className="w-3/4"
            fromItems={generateAgeOptions(10, 27)}
            toItems={generateAgeOptions(10, 27)}
            fromValue={formState.minAge}
            toValue={formState.maxAge}
            error={!!formErrorState.minAge || !!formErrorState.maxAge}
            fromName="minAge"
            toName="maxAge"
            isRequired={true}
            onSelectChange={onSelectChange}
          />
          <InputField
            containerClassNames={`${defaultMargin} mt-5`}
            className="w-3/4"
            id="openingTime-field"
            value={formState.openingTime}
            error={!!formErrorState.openingTime}
            type="text"
            label={t("Opening hour")}
            onChange={handleFormChange("openingTime")}
          />
          <InputSelect
            items={locationTypesList}
            selectedValue={formState.location_type}
            error={!!formErrorState.location_type}
            label={t("Location type")}
            id="location-type-select"
            containerClassName={defaultMargin}
            className="w-3/4"
            onChange={onSelectChange("location_type")}
          />
          <RadioButtons
            id="accessibility-select"
            name="accessibility"
            label={t("Barrier-free access?")}
            options={generateAccessibilityOptions()}
            defaultValue="all"
            value={formState.accessibility}
            onChange={onSelectChange("accessibility")}
          />
          <InputField
            containerClassNames={`${defaultMargin} mt-5`}
            className="w-3/4"
            id="holder-field"
            value={formState.holder}
            error={!!formErrorState.holder}
            type="text"
            label={t("Holder")}
            onChange={handleFormChange("holder")}
          />
        </div>
        <div className="w-full sm:w-1/2">
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="manager-field"
            value={formState.manager}
            error={!!formErrorState.manager}
            type="text"
            label={t("Facility manager")}
            onChange={handleFormChange("manager")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="contactPerson-field"
            value={formState.contactPerson}
            error={!!formErrorState.contactPerson}
            type="text"
            label={t("Contact person")}
            onChange={handleFormChange("contactPerson")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="phone-field"
            value={formState.phone}
            error={!!formErrorState.phone}
            type="text"
            label={t("Phone number")}
            onChange={handleFormChange("phone")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="mobileNumber-field"
            value={formState.mobileNumber}
            type="text"
            label={t("Mobile number")}
            onChange={handleFormChange("mobileNumber")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="email-field"
            value={formState.email}
            error={!!formErrorState.email}
            type="text"
            label={t("Email")}
            onChange={handleFormChange("email")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="website-field"
            value={formState.website}
            type="text"
            label={t("Website")}
            onChange={handleFormChange("website")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="instagram-field"
            value={formState.instagram}
            startText="@"
            type="text"
            label={t("Instagram-Handle")}
            onChange={handleFormChange("instagram")}
          />
          <InputField
            containerClassNames={defaultMargin}
            className="w-3/4"
            id="instagram-field"
            value={formState.tiktok}
            startText="@"
            type="text"
            label={t("Tiktok-Handle")}
            onChange={handleFormChange("tiktok")}
          />
          <ImagePicker
            title={t("Location Photo")}
            description={t("Location Image picker description")}
            formatText={t("Location Image format")}
            filesContent={filesContent}
            image={formState.image.url}
            onClick={onFileChange}
          />
          <div className="block sm:hidden mt-6 mb-4">
            <Button
              className="w-full"
              text={t("Save")}
              loading={formLoading}
              onClick={onSave}
            />
          </div>
        </div>
      </form>
      <ToastContainer />
    </PageContainer>
  );
};
