import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

interface RadioButtonsOption {
  label: string;
  value: string | boolean;
}

interface RadioButtonsProps {
  id: string;
  name: string;
  label: string;
  options: RadioButtonsOption[];
  defaultValue?: string;
  value: string | boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButtons = ({
  id,
  name,
  label,
  options,
  defaultValue = "",
  value,
  onChange,
}: RadioButtonsProps) => {
  return (
    <FormControl>
      <FormLabel id={id}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={id}
        defaultValue={defaultValue}
        name={name}
        value={value}
        onChange={onChange}
        row={true}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
