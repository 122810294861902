import React from "react";
import { Navigate } from "react-router-dom";
import SideBar from '../components/SideBar';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
  isSidebar?: boolean;
  isAuthenticated: () => boolean;
}

export const PrivateRoute = ({
  component: Component,
  isSidebar = true,
  isAuthenticated,
  ...rest
}: PrivateRouteProps) => {

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {isSidebar ? (
        <SideBar>
          <Component {...rest} />
        </SideBar>
      ) : (
        <Component {...rest} />
      )}
    </>
  );
};
