import { useState, createContext, ReactElement, useContext } from "react";
import { Nullable } from "../types/utility";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import { logger } from "../util/logger";
import { IArea } from "../types/events";
import { Collections } from "../types/collections";
import { getCollectionData } from '../helpers/firestoreHelpers';

interface IAreasProvider {
  children: ReactElement;
}

interface IAreasContext {
  areas: IArea[];
  loading: boolean;
  error: Nullable<string>;
  getAreas: () => void;
}

const defaultAreasData = {
  areas: [],
  loading: false,
  error: null,
  getAreas: () => {},
};

export const AreasContext = createContext<IAreasContext>({
  areas: defaultAreasData.areas,
  loading: defaultAreasData.loading,
  error: defaultAreasData.error,
  getAreas: defaultAreasData.getAreas,
});

export const AreasProvider = ({ children }: IAreasProvider) => {
  const [areas, setAreas] = useState<IArea[]>([]);
  const [loading, setLoading] = useState(defaultAreasData.loading);
  const [error, setError] = useState(defaultAreasData.error);

  const getAreas = async () => {
    try {
      logger.info("Getting Areas");
      setLoading(true);

      const areasCollectionRef = collection(db, Collections.Areas);

      const areasSnapshot = await getDocs(areasCollectionRef);
      const areasData = getCollectionData(
        areasSnapshot
      ) as unknown as IArea[];

      if (!areasData.length) {
        throw new Error("No Areas found");
      }

      const data = areasData.sort((a, b) => a.order - b.order);
      setAreas(data);

      logger.info("Successfully Areas fetched", areasData);
    } catch (err: any) {
      logger.error("Error getting Areas", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AreasContext.Provider
      value={{ areas, loading, error, getAreas }}
    >
      {children}
    </AreasContext.Provider>
  );
};

export const useArea = () => useContext(AreasContext);
