import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import classNames from "classnames";
import { colors } from "../../constants/styles";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

interface MobileNavigationModalProps {
  routes: { path: string; name: string; label: string; icon: string }[];
  pathname: string;
  isOpen: boolean;
  toggleModal: () => void;
}

export const MobileNavigationModal = ({
  routes,
  pathname,
  isOpen,
  toggleModal,
}: MobileNavigationModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ background: colors.white, width: "100%", height: "100%" }}>
        <IconButton
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, position: "absolute", top: 15, right: 0 }}
          onClick={toggleModal}
        >
          <CloseIcon />
        </IconButton>
        <div className="h-full w-full flex flex-col justify-center items-center">
          <img
            className="max-w-40 max-h-40 mt-4"
            src={require("../../img/logo_navigation.png")}
            alt="Logo"
          />
          <ul>
            {routes.map((route) => {
              const linkClassName = classNames(
                "m-2",
                "text-lg",
                "font-semibold",
                {
                  "text-primary": pathname === route.path,
                }
              );
              return (
                <li key={route.name} className="my-3">
                  <span className="flex">
                    <img src={route.icon} alt={route.name} />
                    <Link
                      onClick={toggleModal}
                      className={linkClassName}
                      to={route.path}
                    >
                      {route.label}
                    </Link>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </Box>
    </Modal>
  );
};
