import { ToastContainer, toast } from "react-toastify";
import AuthHeader from "../AuthHeader";
import Button from "../Button";
import InputField from "../InputField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { logger } from "../../util/logger";
import { getFunctions, httpsCallable } from "firebase/functions";
import app, { functionsRegion } from "../../firebase";
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../helpers/validation';

const functions = getFunctions(app, functionsRegion);

export const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const resetError = () => {
    setError("");
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      resetError();
    }
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      setError(t("Email is required"));
      return;
    }

    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      setError(t("Invalid email format"));
      return;
    }

    try {
      setLoading(true);
      logger.info("Trying to call forgot password function");
      const forgotPassword = httpsCallable(functions, "forgotPassword");
      const response = await forgotPassword({ email });
      logger.info("Successfully called forgot password function", { response });
      navigate(`/forgot-password-success`);
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      logger.error("Error while trying to call forgot password", {
        errorCode,
        errorMessage,
      });
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lg:w-8/12 sm:w-11/12">
      <AuthHeader activeTab="login" />
      <form onSubmit={handleSubmit} className="w-full mb-4">
        <div className="w-full mb-4">
          <InputField
            className="w-full"
            id="email"
            value={email}
            type="email"
            label={t("Official E-Mail Address")}
            onChange={handleEmailChange}
            error={!!error}
          />
        </div>
        <div className="flex justify-center items-center">
          <Button
            type="submit"
            text={t("Request password")}
            className="w-full"
            loading={loading}
          />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
