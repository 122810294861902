import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InstaIcon from "../../img/icons/instagram.svg";
import ShareIcon from "../../img/icons/share.svg";
import WebIcon from "../../img/icons/web.svg";

export const SocialSharing = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-5">
      <Typography className=" text-label">{t("Share event")}</Typography>
      <Tooltip title={t("Sharing tooltip")}>
        <div className="w-2/4 sm:w-1/4">
          <div className="flex flex-row mt-4">
            <div className="flex mr-2">
              <img src={InstaIcon} alt="Instagram" className="mr-2" />
              <Typography>{t("Instagram")}</Typography>
            </div>
            <img src={ShareIcon} alt="Share" />
          </div>
          <div className="flex flex-row mt-2">
            <div className="flex mr-5">
              <img src={WebIcon} alt="Web" className="mr-2" />
              <Typography>{t("Website")}</Typography>
            </div>
            <img src={ShareIcon} alt="Share" />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
