import Button from '../Button';

interface AuthSuccessProps {
  title: string;
  text: string;
  btnText?: string;
  onClick?: () => void;
}

export const AuthSuccess = ({
  title,
  text,
  btnText,
  onClick,
}: AuthSuccessProps) => {
  return (
    <div className="lg:w-8/12 sm:w-11/12">
      <div className="w-full mb-4 flex justify-center items-center flex-col">
        <img
          src={require("../../img/success_asset.png")}
          alt="success"
          className="w-1/2 mx-auto mb-4"
        />
        <h1 className="mb-2 text-4xl text-primary font-bold">{title}</h1>
        <p className='mb-4 text-center'>{text}</p>
        {btnText && <Button onClick={onClick} text={btnText} className="w-full" />}
      </div>
    </div>
  );
};
