import { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEvents } from "../../context/EventsContext";
import { Nullable } from "../../types/utility";
import { IEvent } from "../../types/events";
import CollapsibleTable from "../../components/CollapsibleTable";
import { useParticipation } from "../../context/ParticipationContext";
import { IDateParticipation } from "../../types/participation";
import { SelectChangeEvent } from "@mui/material";

export const CheckIn = () => {
  const [event, setEvent] = useState<Nullable<IEvent>>(null);

  const { events, cancelEventDate, removeAmendment } = useEvents();
  const { participation, participate, changeParticipationMinutes } =
    useParticipation();

  const navigate = useNavigate();
  const { eventId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const event = events.find((event) => event.id === eventId);
    setEvent(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  if (!event) {
    return null;
  }

  const cancelEvent = (date: string) => () => {
    cancelEventDate(event.id, date, event.amendments);
  };

  const removeCancelAmendment = (date: string) => () => {
    removeAmendment(event.id, date, event.amendments);
  };

  const toggleUserParticipation =
    (userId: string, eventId: string, date: string) => () => {
      participate(userId, eventId, date);
    };

  const goToEdit = () => () => {
    navigate(`/edit-event/${eventId}`);
  };

  const changedEventParticipation =
    (
      dateParticipation: Nullable<IDateParticipation>,
      date: string,
      userId: string
    ) =>
    (e: SelectChangeEvent<string>) => {
      if (dateParticipation) {
        changeParticipationMinutes(
          userId,
          event.id,
          date,
          Number(e.target.value)
        );
      } else {
        participate(userId, event.id, date, Number(e.target.value));
      }
    };

  const eventParticipation = participation.find((p) => p.id === event.id);

  return (
    <PageContainer>
      <PageHeader
        title={`${event.names.de}: ${t("Check-in")}`}
        btnTitle={t("Edit")}
        onBtnClick={goToEdit()}
      />
      <CollapsibleTable
        event={event}
        eventParticipation={eventParticipation}
        cancelEvent={cancelEvent}
        removeAmendment={removeCancelAmendment}
        toggleUserParticipation={toggleUserParticipation}
        changedEventParticipation={changedEventParticipation}
      />
    </PageContainer>
  );
};
