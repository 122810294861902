import React, { FormEvent } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

interface CheckboxOption {
  label: string;
  value: number;
}

interface CheckboxSelectProps {
  options: CheckboxOption[];
  value: number[];
  defaultChecked?: number;
  onChange: (event: FormEvent<HTMLDivElement>) => void;
}

export const CheckboxSelect = ({
  options,
  value,
  defaultChecked = 1,
  onChange,
}: CheckboxSelectProps) => {
  return (
    <div className="mt-2 -ml-6">
      <FormGroup row onChange={onChange}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={value.includes(option.value)}
                defaultChecked={option.value === defaultChecked}
              />
            }
            label={option.label}
            value={option.value}
            labelPlacement="bottom"
          />
        ))}
      </FormGroup>
    </div>
  );
};
