import React, { ReactNode } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface ItemSelect {
  label: string;
  value: string | number;
}

interface InputSelectProps {
  items: ItemSelect[];
  selectedValue: string | number;
  label?: string;
  id: string;
  containerClassName?: string;
  className?: string;
  isRequired?: boolean;
  error?: boolean;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
}

export const InputSelect = ({
  items,
  selectedValue,
  label,
  id,
  containerClassName = "",
  className = "",
  isRequired = false,
  error = false,
  onChange,
}: InputSelectProps) => {
  return (
    <div className={containerClassName}>
      <FormControl className={className}>
        {!!label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
        <Select
          labelId={`${id}-label`}
          id={id}
          value={selectedValue}
          error={error}
          label={label}
          onChange={onChange}
        >
          {!isRequired && <MenuItem key={label} value={label}>
            {label}
          </MenuItem>}
          {items.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
