import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEvents } from "../../context/EventsContext";
import { getEventDateString } from "../../helpers/eventHelpers";
import SignIn from "../../img/icons/sign-in.svg";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import IconBtn from '../../components/IconBtn';
import TrashIcon from "../../img/icons/trash.svg";
import Button from '../../components/Button';
import { colors } from '../../constants/styles';

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Events: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState({ isOpened: false, id: "" });
  const { events, setEventToInactive } = useEvents();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToPage = (path: string) => () => {
    navigate(`/${path}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const openDeleteModal = (id: string) => {
    setIsDeleteModalOpened({ isOpened: true, id });
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpened({ isOpened: false, id: "" });
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const applyDeleteModal = () => {
    setEventToInactive(isDeleteModalOpened.id);
    closeDeleteModal();
  }

  return (
    <PageContainer>
      <PageHeader
        title={t("Events overview")}
        btnTitle={t("Create new event")}
        onBtnClick={goToPage("create-event")}
      />
      <div className="w-full">
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Event")}</TableCell>
                  <TableCell>{t("Category")}</TableCell>
                  <TableCell>{t("Day")}</TableCell>
                  <TableCell>{t("Registrations")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events
                  .filter((event) => event.isActive)
                  .sort((a, b) => b.created_at - a.created_at)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((event) => (
                    <TableRow key={event.id}>
                      <TableCell>{event.names.de}</TableCell>
                      <TableCell>{event.category_name || "-"}</TableCell>
                      <TableCell>{getEventDateString(event)}</TableCell>
                      <TableCell>{event.usersInEvent?.length || 0}</TableCell>
                      <TableCell>
                        <IconBtn
                          src={SignIn}
                          alt="open check-in"
                          onClick={goToPage(`check-in/${event.id}`)}
                        />
                        <IconBtn
                          src={TrashIcon}
                          alt="open check-in"
                          onClick={() => openDeleteModal(event.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={events.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal
        open={isDeleteModalOpened.isOpened}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Are you sure?")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t("This event would be deleted")}
          </Typography>
          <div className="flex justify-end w-full">
            <Button
              text={t("No I don't want")}
              variant="text"
              styles={{ color: colors.primary }}
              onClick={closeDeleteModal}
            />
            <Button
              text={t("JA, BITTE")}
              variant="text"
              styles={{ color: colors.primary }}
              onClick={applyDeleteModal}
            />
          </div>
        </Box>
      </Modal>
    </PageContainer>
  );
};
