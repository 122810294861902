import LoadingButton from "@mui/lab/LoadingButton";
import { buttonStyles } from './button-styles';

interface ButtonProps {
  text: string;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  type?: "button" | "submit" | "reset";
  styles?: { [key: string]: string | number };
  loading?: boolean;
  onClick?: () => void;
}

export const Button = ({
  text,
  className = "",
  variant = "contained",
  type = "button",
  styles = {},
  loading = false,
  onClick = () => {},
}: ButtonProps) => {

  return (
    <LoadingButton
      type={type}
      className={className}
      onClick={onClick}
      variant={variant}
      loading={loading}
      loadingPosition="center"
      sx={{ ...buttonStyles[variant], ...styles }}
    >
      <span>{text}</span>
    </LoadingButton>
  );
};
