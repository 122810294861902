import { axiosInst } from "./";

const GOOGLE_MAP_BASE_URL = "https://maps.googleapis.com/maps/api";

class GoogleMapService {

  public static getCordsByLocation = (address: string) => {
    return axiosInst.get(`${GOOGLE_MAP_BASE_URL}/geocode/json`, {
      params: {
        address,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    });
  };
}

export default GoogleMapService;
