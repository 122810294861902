import { Typography } from "@mui/material";
import UploadImage from "../../img/upload.png";
import { FileContent } from "use-file-picker/dist/interfaces";
import classNames from "classnames";
import ButtonTooltip from '../ButtonTooltip';
import AIStartsIcon from "../../img/icons/ai-stars.svg";

interface ImagePickerProps {
  title: string;
  description: string;
  formatText: string;
  tooltipText?: string;
  filesContent: FileContent<string>[];
  image?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ImagePicker = ({
  title,
  description,
  formatText,
  tooltipText = '',
  filesContent,
  image = "",
  onClick,
}: ImagePickerProps) => {
  const btnClass = classNames(
    { "py-20": !filesContent.length },
    { "px-10": !filesContent.length },
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "rounded-2xl",
    { "border-4": !filesContent.length },
    "border-black",
    "mt-4",
    "max-h-96",
    "max-w-80"
  );

  return (
    <div className="w-3/4">
      <div className="flex flex-row items-center">
        <Typography className=" text-label">{title}</Typography>
        {!!tooltipText && (
          <ButtonTooltip title={tooltipText} icon={AIStartsIcon} alt="stars" />
        )}
      </div>
      <button onClick={onClick} className={btnClass}>
        {filesContent.length ? (
          <>
            {filesContent.map((file, index) => (
              <div key={index}>
                <img
                  className="max-h-96 rounded-2xl"
                  alt={file.name}
                  src={file.content}
                ></img>
              </div>
            ))}
          </>
        ) : image ? (
          <div>
            <img
              className="max-h-96 rounded-2xl"
              alt="firebase-storage"
              src={image}
            ></img>
          </div>
        ) : (
          <>
            <img src={UploadImage} alt="Upload" />
            <Typography>{description}</Typography>
            <Typography>{formatText}</Typography>
          </>
        )}
      </button>
    </div>
  );
};
