import classNames from "classnames";
import { Link } from "react-router-dom";

interface CustomLinkProps {
  to: string;
  title: string;
  isExternal?: boolean;
  className?: string;
}
export const CustomLink = ({
  to,
  title,
  isExternal = false,
  className = "",
}: CustomLinkProps) => {
  const linkClassNames = classNames(
    "text-primary",
    "hover:underline",
    "color-primary",
    className
  );
  return (
    <>
      {isExternal ? (
        <a href={to} className={linkClassNames}>
          {title}
        </a>
      ) : (
        <Link to={to} className={linkClassNames}>
          {title}
        </Link>
      )}
    </>
  );
};
