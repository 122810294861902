import {
  FileWithPath,
  UseFilePickerConfig,
} from "use-file-picker/dist/interfaces";
import { Validator } from "use-file-picker/validators";
import { toast } from "react-toastify";

/**
 * validateBeforeParsing allows all files to be selected
 * validateAfterParsing allows the user to select only files that on portrait orientation
 */
export class ImageCustomValidator extends Validator {
  async validateBeforeParsing(
    config: UseFilePickerConfig<unknown>,
    plainFiles: File[]
  ) {
    return new Promise<void>((res, rej) => {
      if (plainFiles.length) {
        res();
      }
    });
  }
  async validateAfterParsing(
    config: UseFilePickerConfig<unknown>,
    file: FileWithPath,
    reader: FileReader
  ) {
    return new Promise<void>((res, rej) => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = function () {
        if (img.naturalWidth < img.naturalHeight) {
          res();
        } else {
          toast.error("Die Bildbreite sollte kleiner als die Höhe sein");
          rej();
        }
      };
    });
  }
}
