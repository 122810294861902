import { FormControl, SelectChangeEvent, Typography } from "@mui/material";
import { InputSelect } from "../InputSelect/InputSelect";
import { useTranslation } from "react-i18next";
import { EventFormFields } from '../../types/forms';

interface TimeRangeProps {
  id: string;
  label: string;
  className: string;
  fromItems: { label: string; value: string }[];
  toItems: { label: string; value: string }[];
  fromValue: string;
  toValue: string;
  fromName: EventFormFields;
  toName: EventFormFields;
  error?: boolean;
  isRequired?: boolean;
  onSelectChange: (
    name: EventFormFields
  ) => (event: SelectChangeEvent<string | boolean>) => void;
}

export const TimeRange = ({
  id,
  label,
  className,
  fromItems,
  toItems,
  fromValue,
  toValue,
  fromName,
  toName,
  isRequired = false,
  error = false,
  onSelectChange,
}: TimeRangeProps) => {
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <FormControl className={className}>
        <Typography className=" text-label">{label}</Typography>
        <div className="flex flex-row mt-4">
          <InputSelect
            items={fromItems}
            selectedValue={fromValue}
            label={t("From")}
            id={`from-${id}`}
            error={error}
            containerClassName="mb-5"
            isRequired={isRequired}
            onChange={onSelectChange(fromName)}
          />
          <InputSelect
            items={toItems}
            selectedValue={toValue}
            label={t("To")}
            id={`to-${id}`}
            error={error}
            isRequired={isRequired}
            containerClassName="mb-5 ml-8"
            onChange={onSelectChange(toName)}
          />
        </div>
      </FormControl>
    </div>
  );
};
