import React, { useState, createContext, ReactElement, useContext } from "react";
import { logger } from "../util/logger";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { Collections } from "../types/collections";
import {
  AmendmentType,
  IAmendments,
  ICreateEventDTO,
  IEvent,
  IEventDoc,
  IUserProfile,
} from "../types/events";
import { getCollectionData, mapEventsToObject } from "../helpers/firestoreHelpers";
import {
  createAgeRange,
  createDaysObject,
  getIndexing,
} from "../helpers/eventHelpers";
import { emojiRegex } from "../constants";
import { ICreateEventFrom } from "../types/forms";
import { MAX_AGE, MIN_AGE } from "../constants/forms";
import { v4 as uuidv4 } from "uuid";
import { Nullable } from "../types/utility";

interface IEventsProvider {
  children: ReactElement;
}

interface IEventsContext {
  events: IEvent[] | [];
  getEvents: (location_ids: string[]) => Promise<IEvent[]>;
  createEvent: (
    eventId: string,
    event: ICreateEventFrom,
    imagePath?: string
  ) => void;
  cancelEventDate: (
    eventId: string,
    date: string,
    amendments: Nullable<IAmendments[]>
  ) => void;
  removeAmendment: (
    eventId: string,
    date: string,
    amendments: Nullable<IAmendments[]>
  ) => void;
  getUsersForEvents: (newEvents: IEvent[]) => void;
  setEventToInactive: (eventId: string) => void;
}

const defaultEventsStore = {
  events: [],
  getEvents: () => Promise.resolve([]),
  createEvent: () => {},
  cancelEventDate: () => {},
  removeAmendment: () => {},
  getUsersForEvents: () => {},
  setEventToInactive: () => {},
};

export const EventsContext = createContext<IEventsContext>(defaultEventsStore);

export const EventsProvider = ({ children }: IEventsProvider) => {
  const [events, setEvents] = useState<IEvent[]>(defaultEventsStore.events);
  const [locations, setLocations] = useState<string[]>([]);

  const getEvents = async (location_ids: string[]) => {
    if (!location_ids.length) {
      logger.error("No location ids provided to getEvents function");
      return [];
    }

    try {
      const eventsCollectionRef = collection(db, Collections.Events);
      const locationRefs = location_ids.map((id) => doc(db, "Locations", id));
      const q = query(
        eventsCollectionRef,
        where("location", "in", locationRefs)
      );
      const eventsSnapshot = await getDocs(q);
      const eventDocs = eventsSnapshot.docs.map(
        (doc) => doc.data() as IEventDoc
      );
      const response = mapEventsToObject(eventDocs);

      logger.info("Events: ", response);
      setEvents(response);
      setLocations(location_ids);
      return response;
    } catch (err) {
      logger.error("Error in getting Events", err);
    }
    return [];
  };

  const createEvent = async (eventId: string, event: ICreateEventFrom, imagePath?: string) => {
    if (!event.location) {
      logger.error("No location provided to createEvent function");
      return;
    }
    try {
      // Create event
      const categoryCollectionRef = collection(db, Collections.Categories);
      const locationCollectionRef = collection(db, Collections.Locations);
      logger.info("Creating Event");

      const timestamp = new Date().getTime();
      const descriptionWithoutEmoji = event.description.replace(emojiRegex, "");

      const data: ICreateEventDTO = {
        id: eventId,
        names: {
          de: event.name,
        },
        days: event.recurring_event
          ? createDaysObject(event.days)
          : createDaysObject([]),
        start_time: event.start_time,
        end_time: event.end_time,
        recurring_event: event.recurring_event,
        cost: event.price,
        // TODO: remove hardcoded points with actual points, for now there is no requirements
        points: 1,
        descriptions: {
          de: event.description,
        },
        descriptionWithoutEmoji,
        image: imagePath || `images/events/${eventId}`,
        start_date: event.start_date.split("T")[0],
        end_date: event.end_date.split("T")[0],
        location_name: event.location.names.de!,
        location: doc(locationCollectionRef, event.location.id),
        location_details: { ...event.location },
        gender: event.gender,
        category_name: "",
        isActive: true,
        ageRules: {},
        feed: [],
        created_at: timestamp,
        updated_at: timestamp,
        usersInEvent: event.usersInEvent || [],
        lgbtqi: event.lgbtqi,
        isSchoolEvent: event.isSchoolEvent,
      };

      if (event.category) {
        data.category = doc(categoryCollectionRef, event.category.id);
        data.categoryId = event.category.id;
        data.category_name = event.category.label.de
          ? event.category.label.de
          : "";
        data.category_details = { ...event.category };
      }

      if (event.minAge || event.maxAge) {
        const max = Number(event.maxAge || MAX_AGE);
        const min = Number(event.minAge || MIN_AGE);
        data.ageRules = {
          min,
          max,
        };
        data.ageRange = createAgeRange(min, max);
        data.isNoAgeRules = false;
      } else {
        data.ageRange = [];
        data.isNoAgeRules = true;
        data.ageRules = {};
      }
      const eventsCollectionRef = collection(db, Collections.Events);
      await setDoc(doc(eventsCollectionRef, eventId), data);
      logger.info("Event created with ID: ", eventId);

      await getEvents(locations);
  
      const indexing = getIndexing(data);
      const indexingCollectionRef = collection(db, Collections.Indexing);
      await setDoc(doc(indexingCollectionRef, eventId), indexing);

      logger.info("Indexing for event created");
    } catch (err) {
      logger.error("Error in creating Event", err);
    }
  };

  const cancelEventDate = async (
    eventId: string,
    date: string,
    amendments: Nullable<IAmendments[]>
  ) => {
    try {
      const now = new Date().getTime();
      const eventRef = doc(db, Collections.Events, eventId);
      const previousAmendments = amendments || [];
      const newAmendments = [
        ...previousAmendments,
        {
          id: uuidv4(),
          type: AmendmentType.cancel,
          effected_date: date,
          created_at: now,
          updated_at: now,
        },
      ];

      await setDoc(eventRef, { amendments: newAmendments }, { merge: true });
      const newEvents = events.map((event) => {
        if (event.id === eventId) {
          return {
            ...event,
            amendments: newAmendments,
          };
        }
        return event;
      });
      setEvents(newEvents);
      logger.info("Amendment created for event date");
    } catch (err) {
      logger.error("Error in Amendment creation", err);
    }
  };

  const updateAmendments = (eventId: string, amendments: IAmendments[]) => {
    return events.map((event) => {
      if (event.id === eventId) {
        return {
          ...event,
          amendments,
        };
      }
      return event;
    });
  };

  const removeAmendment = async (
    eventId: string,
    date: string,
    amendments: Nullable<IAmendments[]>,
  ) => {
    try {
      const previousAmendments = amendments || [];
      const newAmendments = previousAmendments.filter((amendment) => amendment.effected_date !== date);
      const eventRef = doc(db, Collections.Events, eventId);
      await setDoc(eventRef, { amendments: newAmendments }, { merge: true });
      const newEvents = updateAmendments(eventId, newAmendments);
      setEvents(newEvents);
      logger.info("Amendment removed for event");
    } catch (err) {
      logger.error("Error in removing Amendment", err);
    }
  };

  const getUsersForEvents = async (newEvents: IEvent[]) => {
    const allUserInEvents = newEvents.map((event) => {
      const users = event.usersInEvent || [];
      return users.map((user) => {
        return user.id;
      });
    });
    const allUserIds = allUserInEvents.flat();
    const uniqueUserIds = Array.from(new Set(allUserIds));

    if (!uniqueUserIds.length) {
      logger.info("There is no events with users");
      return;
    }

    try {
      const userProfileRef = collection(db, Collections.UserProfile);
      const q = query(
        userProfileRef,
        where("id", "in", uniqueUserIds)
      );
      const usersSnapshot = await getDocs(q);
      const usersDocs = getCollectionData(
        usersSnapshot
      ) as unknown as IUserProfile[];

      const eventsWithUsers = newEvents.map((event) => {
        const users = event.usersInEvent || [];
        return {
          ...event,
          usersInEvent: usersDocs.filter((user) => {
            return users.some((eventUser) => eventUser.id === user.id);
          }),
        };
      });
      setEvents(eventsWithUsers);
      logger.info("Users for events successfully fetched");
    } catch (err) {
      logger.error("Error in getting users for events", err);
    }
  }

  const setEventToInactive = async (eventId: string) => {
    try {
      const eventRef = doc(db, Collections.Events, eventId);
      await setDoc(eventRef, { isActive: false }, { merge: true });
      const newEvents = events.map((event) => {
        if (event.id === eventId) {
          return {
            ...event,
            isActive: false,
          };
        }
        return event;
      });
      setEvents(newEvents);
      logger.info("Event set to inactive");
    } catch (err) {
      logger.error("Error in setting event to inactive", err);
    }
  }

  return (
    <EventsContext.Provider
      value={{
        events,
        getEvents,
        createEvent,
        cancelEventDate,
        removeAmendment,
        getUsersForEvents,
        setEventToInactive,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => useContext(EventsContext);
